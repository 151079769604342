body,
html {
    scrollbar-width: thin
}

.backgroundcard,
.playcard {
    backdrop-filter: blur(30px);
    z-index: 1;
    opacity: 1
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(@sweetalert2/theme-dark/dark.css);

@font-face {
    font-family: tcbregular;
    src: url('/src/assets/fonts/TCCB-regular.TTF')
}

@font-face {
    font-family: tcbnormal;
    src: url('/src/assets/fonts/TCCM-Normal.TTF')
}

@font-face {
    font-family: tcbbold;
    src: url('/src/assets/fonts/TwCenMTCondensedBold.ttf')
}

html {
    scroll-behavior: smooth;
    --scrollbarBG: #010506;
    --thumbBG: #9029d9;
    scrollbar-color: #9029d9 #010506
}

body::-webkit-scrollbar {
    width: 11px
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG)
}

body::-webkit-scrollbar-track {
    background: var(--scrollbarBG)
}

body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG)
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.queue-column {
    flex: 0 0 49%;
    max-width: 49%
}

@media (max-width:767px) {
    .container {
        flex-direction: column
    }

    .queue-column {
        flex: 0 0 100%;
        max-width: 100%
    }
}

.backgroundcard {
    background: radial-gradient(closest-side at 50% 50%, #9c08FEA8 0, #00235800 100%) no-repeat padding-box;
    -webkit-backdrop-filter: blur(30px)
}

.playcard {
    -webkit-backdrop-filter: blur(30px);
    background: linear-gradient(180deg, #010101 0, #00102a 100%) no-repeat padding-box
}

.jptext {
    opacity: 1;
    color: transparent linear-gradient(259deg, #13ebfd 0%, #f807e7 100%) 0% 0% no-repeat padding-box !important
}

.winnerscard {
    background: radial-gradient(closest-side at 16% 13%, #7900ff 0, #007780 100%) no-repeat padding-box;
    border-radius: 34px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px)
}

.lottery-bg {
    background: radial-gradient(closest-side at 50% 50%, #9c08FEA8 0, #000 100%) no-repeat padding-box
}

.cardtext {
    color: #8e90ff
}

.show-counter {
    padding: .5rem
}

.show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 750;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: .5rem;
    text-decoration: none;
    color: #ebebeb
}

.show-counter .countdown {
    background: linear-gradient(#f00fe8, #234e9c);
    background: -webkit-linear-gradient(#f00fe8, #234e9c);
    background: -moz-linear-gradient(#f00fe8, #234e9c);
    background: -o-linear-gradient(#f00fe8, #234e9c);
    background: -ms-linear-gradient(#f00fe8, #234e9c);
    border: 1px solid #ebebeb;
    border-radius: .25rem;
    line-height: 1.2;
    height: 50px;
    padding: 0 .75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.herobackground,
.heroblackbackground {
    opacity: 1;
    padding: 8px;
    margin: 0
}

.show-counter .countdown.danger {
    color: red
}

.show-counter .countdown>p {
    margin: 0
}

.show-counter .countdown>span {
    text-transform: uppercase;
    font-size: .75rem;
    line-height: .75rem
}

.bannertext {
    text-align: center;
    font: bold 105px/112px Tw Cen MT;
    letter-spacing: 0;
    color: #fff;
    text-transform: uppercase;
    opacity: 1
}

.bannerbackground {
    background: linear-gradient(259deg, #f00fe8 0, #13ebfd 100%) no-repeat padding-box;
    mix-blend-mode: hard-light;
    opacity: 1
}

.herobackground {
    background: radial-gradient(60% 52%, #5b0396e8 0, #00235800 100%) 50% 0 no-repeat
}

.heroblackbackground {
    background: rgba(0, 0, 0, .7);
    border-radius: 3%;
}

.custom-button:hover {
    background-color: #8e90ff;
    color: #fff
}

.custom-button.clicked {
    background-color: #b01515;
    color: #fff
}